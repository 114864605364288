import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/scuola2.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Partecipazione degli Enti Pubblici
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            Gli Enti Pubblici possono essere soci di una cooperativa.
          </p>
          <p>
            Sussidiarietà orizzontale: la cooperativa crea le condizioni per
            favorire quei cittadini che promuovono iniziative con cui si
            realizza un interesse generale, oltre che dei singoli. L’interesse
            generale diventa un "ponte" che unisce i cittadini ed i soggetti
            pubblici.
          </p>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
