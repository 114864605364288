import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/2499757.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Partecipazione: democratica e aperta
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            Le decisioni più importanti per la CER non sono quelle tecniche
            (decisioni di architetti o ingegneri) ma quelle per garantire una
            buona convivenza all’interno della Comunità (decisioni dei membri
            della Comunità).
          </p>
          <p>
            L’organizzazione della democrazia interna deve rispecchiare
            l’interesse di tutti i membri. La forma cooperativa garantisce:
          </p>
          <ul>
            <li>
              Controllo democratico da parte dei soci: "una testa un voto" a
              prescindere dall’investimento di ciascuno
            </li>
            <li>
              Principio della porta aperta: i soci hanno sempre libertà di
              accesso/recesso
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
