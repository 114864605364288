import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/20944720.jpg";
function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Personalità giuridica e separazione patrimoniale
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            La Cooperativa ha una propria personalità giuridica quindi, tra le
            altre cose, gode di "autonomia patrimoniale" rispetto ai suoi soci.
          </p>
          <p>
            Non ci sono profili di responsabilità personale e solidale dei
            soggetti che agiscono o assumono obbligazioni in nome e per conto
            della cooperativa.
          </p>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
