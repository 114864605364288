import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/mutualita.png";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Mutualità e assenza lucro
      </h3>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <p style={{ marginBottom: "20px" }}>
            Gli scopi primari della cooperativa sono sempre di tipo
            mutualistico, come il contrasto alla povertà energetica o la
            transizione verso un’economia più sostenibile e più giusta. La
            mutualità si intende sia interna tra i soci che esterna con benefici
            all’ambiente, sociali ed alla comunità.
          </p>
          <p style={{ marginBottom: "20px" }}>
            Proprio in virtù dello scopo mutualistico e dell’assenza di scopo di
            lucro, gode di benefici come un regime fiscale agevolato ed ha
            obbligo di trasparenza finanziaria.
          </p>
          <p>
            Ha un accesso semplificato alla qualifica di "impresa sociale" (ed
            in questo caso gode di ulteriori benefici fiscali).
          </p>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
