import { Box, Grid } from "@mui/material";
import React from "react";

import img from "../assets/Wavy_Eco-08_Single-02.jpg";

function P9_Modal() {
  return (
    <div>
      <h3
        className="text-center"
        style={{ marginBottom: "20px", color: "#AB2B36" }}
      >
        Struttura imprenditoriale: investimenti e proprietà
      </h3>

      <p style={{ marginBottom: "20px" }}>
        La cooperativa ha una struttura di gestione simile a quella di
        un’impresa, anche se più semplice (per esempio non serve un notaio per
        cambiare base sociale o capitale sociale).
      </p>
      <p>
        Oltre ad agevolare la gestione, questo facilita anche gli investimenti
        per avere impianti di proprietà:
      </p>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <ul>
            <li>
              Il capitale variabile agevola gli investimenti successivi (anche
              con gli strumenti finanziari del sistema cooperativo)
            </li>
            <li>Possibilità di crowdfunding</li>
            <li>
              Migliore accesso ai finanziamenti (rispetto a soggetti non
              imprenditoriali)
            </li>
            <li>Accesso al fondo mutualistico</li>
            <li>L’IVA non è un costo</li>
            <li>
              Struttura in grado di gestire impianti nel tempo (manutenzioni,
              etc...)
            </li>
            <li>
              Gli utili reinvestiti nella cooperativa riducono la base
              imponibile IRES
            </li>
          </ul>
        </Grid>
        <Grid item xs={4}>
          <Box component={"img"} src={img} width="100%"></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default P9_Modal;
