import React, { useState } from "react";

import { Box, Grid } from "@mui/material";

import Navigation from "../components/Navigation";
import LateralMenu from "../components/LateralMenu";
import CoopCard from "../components/CoopCard";
import CustomModal from "../components/CustomModal";
import ContentSelector from "../components/ContentSelector";

import P9_Modal1 from "./P9_Modal1";
import P9_Modal2 from "./P9_Modal2";
import P9_Modal3 from "./P9_Modal3";
import P9_Modal4 from "./P9_Modal4";
import P9_Modal5 from "./P9_Modal5";
import P9_Modal6 from "./P9_Modal6";

import logo from "../assets/logo_legacoop_short.jpg";
import icon1 from "../assets/2499757.jpg";
import icon2 from "../assets/scuola2.jpg";
import icon3 from "../assets/mutualita.png";
import icon4 from "../assets/20944720.jpg";
import icon5 from "../assets/Wavy_Eco-08_Single-02.jpg";
import icon6 from "../assets/5209019.jpg";

const cards = [
  { title: "Partecipazione: democratica e aperta", icon: icon1 },
  { title: "Partecipazione degli Enti Pubblici", icon: icon2 },
  { title: "Mutualità e assenza lucro", icon: icon3 },
  { title: "Personalità giuridica e separazione patrimoniale", icon: icon4 },
  { title: "Struttura imprenditoriale: investimenti e proprietà", icon: icon5 },
  { title: "Ripartizione degli incentivi e degli altri ricavi", icon: icon6 },
];

export default function P9Coop() {
  const [showModal, setShowModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(0);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        backgroundColor: "white",
      }}
    >
      <LateralMenu selected={3} image={logo} />

      <Grid container spacing={4} p={6} alignContent="center">
        {cards.map((card, index) => (
          <Grid item xs={4} key={card.title}>
            <CoopCard
              icon={card.icon}
              title={card.title}
              onClick={() => {
                setSelectedCard(index);
                setShowModal(true);
              }}
            />
          </Grid>
        ))}
      </Grid>

      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        showCloseIcon={true}
        width="60%"
      >
        <ContentSelector selectedChild={selectedCard}>
          <P9_Modal1 />
          <P9_Modal2 />
          <P9_Modal3 />
          <P9_Modal4 />
          <P9_Modal5 />
          <P9_Modal6 />
        </ContentSelector>
      </CustomModal>

      <Navigation back="/p9" forward="/p10" />
    </div>
  );
}
